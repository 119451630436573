import React from 'react'
import Modal from "./Modal";

const Footer = () => (

    <div><p>&copy; 2015 -2020 Cultbabes.com | <Modal /></p></div>

)


  export default Footer