import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Link from '@material-ui/core/Link';

function getModalStyle() {
    return {
        top: '45%',
        left: '56%',
        transform: 'translate(-60%, -41%)',
        height: '80vh',
        overflow: 'auto',
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function SimpleModal() {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div className="PopUp">
                <div className="PopUpGroup">
                    <div className="CloseIcon" onClick={handleClose}>
                        <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.05029 5.05023L14.9498 14.9497" stroke="black" strokeWidth="2"/>
                            <path d="M5.05029 14.9498L14.9498 5.05027" stroke="black" strokeWidth="2"/>
                        </svg>
                    </div>

                    <h2>Impressum</h2>

                    <div className="PopUpTextGroup">
                        <p>VISIT-X B.V.<br/>
                            Krijn Taconiskade 424 Unit 3-6<br/>
                            1087 HW Amsterdam<br/>
                            Netherlands<br/>
                            Tel. 00 800 300 000 77
                        </p>
                    </div>


                    <div className="PopUpTextGroup">
                        <div className="PopUpHeadline">Presseanfragen:</div>
                        <p>presse@visit-x.net</p>
                    </div>


                    <div className="PopUpTextGroup">
                        <div className="PopUpHeadline">Managing Director:</div>
                        <p> Markus Schreiber</p>
                    </div>


                    <div className="PopUpTextGroup">
                        <div className="PopUpHeadline">Commercial register number:</div>
                        <p>34213541</p>
                    </div>


                    <div className="PopUpTextGroup">
                        <div className="PopUpHeadline">Register court:</div>
                        <p> Amsterdam, Netherlands</p>
                    </div>

                    <div className="PopUpTextGroup">
                        <div className="PopUpHeadline">Online Streitbeilegung:</div>
                        <p>Beschwerdeverfahren via Online-Streitbeilegung für Verbraucher (OS):
                            http://ec.europa.eu/consumers/
                            odr VISIT-X ist nicht bereit und nicht verpflichtet, an einem Streitbeilegungsverfahren vor
                            einer
                            Verbraucherschlichtungsstelle teilzunehmen.
                        </p>
                    </div>


                    <div className="PopUpHeadline">Jugendschutzbeauftragter gemäß § 7 JMStV:</div>
                    <p>
                        Rechtsanwalt Marko Dörre<br/>
                        Marienstraße 8<br/>
                        10117 Berlin, Germany<br/>
                        Tel.: +49 (0) 30 400544 99<br/>
                        Fax: +49 (0) 30 400544 98<br/>
                    </p>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Link type="button" onClick={handleOpen} color="inherit">
                Impressum
            </Link>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
}
