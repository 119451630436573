import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import ComingSoon from './components/ComingSoon';
import Logo from './components/Logo';
import Footer from './components/Footer';



function App() {
  return (
      <HelmetProvider>
          <Helmet>
              <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
              />
              <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
              />
              <link rel="manifest" href="/site.webmanifest" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
              <meta name="msapplication-TileColor" content="#da532c" />
              <meta name="theme-color" content="#ffffff" />
              <meta charSet="utf-8" />
              <title>CultBabes</title>
          </Helmet>
          <div>
            <div className="HeroGroup">

              <div className="HeroLogo">
                <Logo />
              </div>

              <div className="HeroComingSoon">
                <ComingSoon />
              </div>

              <div className="Footer">
                <Footer />
              </div>
            </div>
          </div>
      </HelmetProvider>
  );
}

export default App;
